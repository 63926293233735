import { useState, useEffect } from "react"
import icon from "./deepobjects.webp"
import useHypermint from "../hooks/useHypermint"
import Web3 from 'web3'
import os from '../assets/opensea-black.png'

const CLOSED = true;

const Claim = (props) => {
    const contractInstance = useHypermint();
    const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('')
    const [actionText, setActionText] = useState('')
    const [countToAddress, setCountToAddress] = useState({})

    const [successfulMint, setSuccessfulMint] = useState(false)

    const [numToMint, setNumToMint] = useState(0)

    const [count, setCount] = useState(0)

    const checkConnected = async () => {
        // Check if the user is connected to the wallet
        setLoading(true)
        try {
            const connectedWallet = await contractInstance.getConnectedWallet();
            if (connectedWallet?.isConnected) {
                console.log('Connected wallet: ', connectedWallet)
                setUser(connectedWallet);
                // console.log("Checking allocation for :", connectedWallet.address.toLowerCase())
                let amountToMint = await contractInstance.getTokenAllocation(0, connectedWallet.address.toLowerCase())
                // console.log("amountToMint", amountToMint)
                setCount(amountToMint)
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }

    }



    const checkTx = async (hash) => {
        const { ethereum } = window;
        if (ethereum) {
            // Log which tx hash we're checking
            const web3 = new Web3(Web3.givenProvider);
            // Set interval to regularly check if we can get a receipt
            return await new Promise(resolve => {
                let interval = setInterval(() => {

                    web3.eth.getTransactionReceipt(hash, (err, receipt) => {
                        if (err) {
                            console.log(err)
                            resolve("Error")
                            clearInterval(interval)
                        }
                        // If we've got a receipt, check status and log / change text accordingly
                        if (receipt) {
                            if (receipt.status === true) {
                                resolve('Success');
                            } else if (receipt.status === false) {
                                resolve('Failed');
                            }

                            // Clear interval
                            clearInterval(interval)
                        }
                    })
                }, 1000)
            })
        } else {
            console.log('Ethereum object does not exist!');
            return "Error"
        }
    }

    const mint = async () => {

        try {
            if (user) {
                setLoading(true)
                setActionText("Validating User...")
                let waitForTransaction = false
                setActionText("Waiting for user action...")
                const transaction = await contractInstance.buyAuthorised(numToMint, 0, waitForTransaction)
                setActionText("Waiting for mint to confirm on chain...")
                // console.log("transaction", transaction)
                const status = await contractInstance.getTransactionStatus(transaction);
                console.log("status", status)
                let amountToMint = await contractInstance.getTokenAllocation(0, user.address.toLowerCase())
                // console.log("amountToMint", amountToMint)
                setCount(amountToMint)
                setSuccessfulMint(true)
                setActionText("")
                setLoading(false)

            } else {
                alert('Please connect to your wallet')
            }
        } catch (e) {
            console.log(e);
            alert(`Something went wrong while minting. \n ${e.message}`)
            setLoading(false)
        }
    }


    useEffect(() => {
        if (!contractInstance) return;
        checkConnected(contractInstance);

    }, [contractInstance]);



    return <div
        style={{
            fontFamily: 'inter-bold'
        }}

        className="w-full h-screen text-white 
    
    
    
    flex flex-col justify-center items-center">
        <div className="absolute top-0 opacity-25 left-0 w-full h-full flex flex-col justify-center items-center z-0">
            {/* <img src={puma} alt='puma' className=" animate-pulse" /> */}
        </div>

        <div
            className="w-screen absolute top-0 left-0 text-white flex flex-col justify-between px-2 sm:flex-row"
        >
            <p


            >DEEP OBJECTS - STUDIO PASS</p>
            {user && <p


            >CONNECTED : {user.address.substring(0, 5).toUpperCase()}...{user.address.substring(38).toUpperCase()}</p>}

        </div>



        <div className="flex flex-col justify-center items-center w-screen h-screen absolute top-0 left-0">
            {contractInstance && !loading ? (
                <div>
                    {user ? (<div className="flex flex-col space-y-2">


                        {count && count[0]?.remainingAllocation > 0 ?
                            (<div>

                                <p className="text-4xl mb-5">STUDIO PASS MINT</p>
                                <div className=" bg-[#F3F7FD] p-5 rounded-xl relative">

                                    {successfulMint && (<div className="rounded-xl flex flex-col justify-center items-center bg-[#F3F7FD] w-full h-full absolute top-0 left-0">
                                        <p className="text-black text-2xl">MINT SUCCESSFUL</p>
                                        <div className="flex flex-row space-x-5 items-center justify-center mt-5">
                                            <a
                                                href="https://opensea.io/collection/deepobjects-ai-studio-pass"
                                                target="_blank"
                                                rel="noreferrer"

                                            >
                                                <img src={os} alt="os" className="w-10" />
                                            </a>
                                            <button
                                                onClick={() => {
                                                    setSuccessfulMint(false)
                                                }
                                                }
                                                className="border-2 border-black px-3 py-2 rounded-xl text-black"
                                            >
                                                Go Back
                                            </button>
                                        </div>
                                    </div>)}



                                    <p className="text-black text-sm">
                                        ALLOCATION [ {count[0]?.remainingAllocation} ]
                                    </p>
                                    <div className="flex flex-row  justify-center  text-black">
                                        <button
                                            className="bg-black px-3 hover:bg-opacity-75 font-bold text-[#F3F7FD] rounded-l-xl text-3xl"
                                            onClick={() => {
                                                if (numToMint > 1) setNumToMint(numToMint - 1)
                                            }}
                                        >-</button>
                                        <p
                                            className="border-2 border-black text-3xl w-[100px]  px-4 py-2"
                                        >{numToMint}</p>
                                        <button
                                            className="bg-black px-3 hover:bg-opacity-90 font-bold text-[#F3F7FD] rounded-r-xl text-3xl"
                                            onClick={() => {
                                                if (numToMint < count[0]?.remainingAllocation) setNumToMint(numToMint + 1)

                                            }}
                                        >+</button>
                                    </div>
                                    <div className="flex flex-row space-x-5 justify-center">
                                        <button
                                            className="border-2 border-black mt-5 px-3 py-2 rounded-lg text-xl text-black"
                                            onClick={async () => {
                                                setNumToMint(count[0]?.remainingAllocation)
                                            }}
                                        >
                                            Set Max
                                        </button>

                                        <button
                                            className="border-2 border-black mt-5 px-3 py-2 rounded-lg text-xl text-black"
                                            onClick={async () => {
                                                await mint()
                                            }}
                                        >
                                            Mint
                                        </button>

                                    </div>

                                </div>

                            </div>) : (
                                <div className="relative ">
                                    <p className="text-4xl mb-5">STUDIO PASS MINT</p>
                                    <div className={` bg-[#F3F7FD] p-5 rounded-xl `}>

                                        {successfulMint && (<div className="rounded-xl flex flex-col justify-center items-center bg-[#F3F7FD] w-full h-full absolute top-0 left-0">
                                            <p className="text-black text-2xl">MINT SUCCESSFUL</p>
                                            <div className="flex flex-row space-x-5 items-center justify-center mt-5">
                                                <a
                                                    href="https://opensea.io/collection/deepobjects-ai-studio-pass"
                                                    target="_blank"
                                                    rel="noreferrer"

                                                >
                                                    <img src={os} alt="os" className="w-10" />
                                                </a>
                                                <button
                                                    onClick={() => {
                                                        setSuccessfulMint(false)
                                                    }
                                                    }
                                                    className="border-2 border-black px-3 py-2 rounded-xl text-black"
                                                >
                                                    Go Back
                                                </button>
                                            </div>
                                        </div>)}



                                        <p className="text-black text-sm">
                                            SORRY, THIS WALLET DOES NOT HAVE ANY ALLOCATION LEFT
                                        </p>

                                    </div>
                                </div>
                            )}



                    </div>) : (<div
                        className="flex flex-col space-y-4 justify-center items-center"
                    >

                        {CLOSED ?
                            <>
                                <p
                                    className="bg-black flex flex-row space-x-2 justify-center items-center text-white border-2 border-white font-bold px-4 py-2 rounded-md"

                                >
                                    CLAIM
                                    <img src={icon} className="h-5 mx-2" />
                                    CLOSED
                                </p>
                            </>
                            :
                            <button

                                className="bg-black flex flex-row space-x-2 justify-center items-center text-white border-2 border-white font-bold px-4 py-2 rounded-md"

                                onClick={async () => {
                                    // Connect to the wallet
                                    await contractInstance.connect();
                                    await checkConnected(contractInstance);
                                }
                                }
                            >
                                CONNECT
                                <img src={icon} className="h-5 mx-2" />
                                WALLET
                            </button>
                        }


                    </div>)}

                </div>
            ) : (
                <div className="flex flex-col justify-center items-center  space-y-2">
                    {actionText ? <div className="flex flex-col items-center justify-center space-y-5">
                        <img src={icon} alt='deepObjectsLogo ' className="h-10 animate-spin" />
                        <p
                            className="bg-[#F3F7FD] text-black font-bold px-4 py-2 rounded-md"

                        >{actionText}</p>
                    </div> : <div className="animate-spin mt-5">
                        <img src={icon} alt='deepObjectsLogo' />
                    </div>}

                </div>
            )}
        </div>

    </div >
}

export default Claim